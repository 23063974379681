import React from "react";
import { Route, Router } from "react-router-dom";
import { Switch } from "react-router";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import { createBrowserHistory } from "history";

import Home from "components/Home"

const history = createBrowserHistory();
const SiteRouter = (props) => {

         return(
            <Router history={history}>
             <Switch>
                 <Route component={Home} />
             </Switch>
            </Router>
         )
 
 }

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({}, dispatch)
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SiteRouter)
)