
import React,{useState} from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Navbar,Row,Col,Container } from 'react-bootstrap';
import { mainAction } from 'redux/actions/index.actions'
import { actions } from 'redux/actions/types'
import {ListUnorderedIcon,SearchIcon,XIcon} from '@primer/octicons-react'
const  NavigationBar = (props) => {
  const [ show, setShow ] = useState(props.sidebar)
  const loadSideNav = () => {
    setShow(true)
    props.actions.mainAction(actions.SHOW_SIDE_BAR,true)
  }
  const hideSideBar = () =>{
    setShow(false)
    props.actions.mainAction(actions.HIDE_SIDE_BAR,true)
}
    return (
      <> 
      <section className="navigationbar">
      <Container>
          <Row>
              <Col lg="12">
                <ul>
                    <li><SearchIcon size={24} /></li>  
                    <li><Navbar.Brand href="/" className="logo">Fashion<span>L</span>edge</Navbar.Brand></li> 
                    <li onClick={()=>loadSideNav()}><ListUnorderedIcon size={24} onClick={()=>loadSideNav()} /></li> 
                </ul>
            </Col>
          </Row>
      </Container>
      </section>
      {show ? <section className="sidenav">
        <div className="close" onClick={()=>hideSideBar()}><XIcon size={24} onClick={()=>hideSideBar()} /></div>
        <ul>
            <li>news</li>
            <li>fashion</li>
            <li>events</li>
        </ul>
    </section> : ""}
    </>
    )
}
function mapStateToProps(state) {
    return {
      sidebar:state.sidenavreducer
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators(
        {
          mainAction
        },
        dispatch
      )
    };
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(NavigationBar);