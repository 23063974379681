import React from "react";
const Home = () => {
    return (<>
    <div className="article-container container" >
        <div className="row">
            <div className="col-6">
                <img src="/images/tolson.png" width="100%" height="100%" object-fit="contain" alt="tolson"/>
                <div className="photo-credit">Wall Lighting Photo: Rejuvenation.com</div>
            </div>
            <div className="col-5">
                <h1>STILL HUNKERED DOWN? 4 IDEAS TO CURB YOUR COVID-19 UNREST</h1>
                <div className="writer">By Kenyatta D. Pious</div>
                <div className="article-cotent"><p>This is the time to update your living space.  It doesn’t have to be an exhausting DIY project.  Tackle a space that you frequent such as living room, bedroom or bathroom.  I find that tiny changes to detail can go a long way.  Adding or removing art and furniture can open up a space and brighten the mood.</p>
                 <p>Check out rejuvenation.com for some ideas on chandeliers, pendants and wall lighting for most budgets.  Use promo: XBHLQ2L2206106 for 20% off on your first order.</p> 


                </div>
            </div>
        </div>
    </div>
    <div className="article-container container" >
        <div className="row">
            <div className="col-6">
                <img src="/images/fort-green-park.png" width="100%"  object-fit="contain" alt="fort-green"/>
                <div className="photo-credit">Photo: www.fortgreenepark.org</div>
            </div>
            <div className="col-5">
                <h1>VOLUNTEER YOUR TIME OR EXPERTISE</h1>
                <div className="article-cotent"><p>TMany of us have specific skill sets and Covid-19 has affected so many people in many ways.  A way to curb unrest could be to figure out how you can volunteer your time or expertise.  You do not have to be a first responder to make a difference.</p>
                <p>Here are some ideas: speak with churches and civic groups about figuring out ways to help senior citizens including simple phone calls to check in on them.  </p>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col">
              <p style={{marginTop:"25px"}}>If you are technologically inclined, perhaps work with seniors who may need to learn how to use apps for banking, audiobooks or staying connected on social media.  Donate your time to deliver groceries, essential items or meals.  You can also help those parents who are heading back to work with childcare.  You can help to steward, beautify and maintain a park (Fort Greene Park in Brooklyn, NYC pictured above).  There are countless ways to help out.  A simple Google search will reap many ideas.
</p><p>Anything you decide to do should be done with safety precautions.  Follow CDC guidelines and your local city/state guidelines.</p>  
            </div>
        </div>
    </div>
    <div className="article-container container" >
        <div className="row">
            <div className="col-4">
                <img src="/images/where-the-crawdads-sing.png" width="100%" height="100%" object-fit="contain" alt="where the crawdads sing"/>
                <div className="photo-credit">Photo: Amazon.com</div>
            </div>
            <div className="col-7">
                <h1>REDISCOVER THE JOY OF READING</h1>
                <div className="article-cotent"><p>At a time when watching the news can be sad and depressing, the best thing to do may be to hunker down with a book.  “Where the Crawdads Sing” is a #1 New York Times Bestseller that spent at least 96 weeks on the list!</p>
                 <p>I confess I have not read this book, but it is part of my book club and is next up for me.  I hope it holds up to the hype.  It is available here.</p> 


                </div>
            </div>
        </div>
    </div>
    <div className="article-container container" >
        <div className="row">
            <div className="col-4">
                <img src="/images/moose-math.jpg" width="100%"  object-fit="contain" alt="moose math"/>
                <div className="photo-credit">Photo: google.com</div>
            </div>
            <div className="col-8">
                <h1>KEEP THE LITTLE ONES ENTERTAINED…WHILE THEY LEARN</h1>
                <div className="article-cotent"><p>There are a slew of tech games for kids.  While I don’t encourage children sitting in front of devices for a ton of time, I realize that many children will spend some time on them (and sometimes you need them to be on gadgets for your sanity).  Therefore, I recommend that you give them apps that teach.</p>
                 <p>In this current time, many children do not know when they will return to schools and it is important that they continue to expand their cognitive abilities.  Moose Math by Duck Duck Moose teaches counting, addition, subtraction, sorting and geometry.   Moose Math is aligned with Common Core State Standards for Kindergarten and 1st Grade.  (Ages 3-7; Free, App Store and Google Play</p> 


                </div>
            </div>
        </div>
    </div>
    <div className="article-container container" >
        <div className="row">
            <div className="col-6">
                <img src="/images/kate-spade-masks.png" width="100%"  object-fit="contain"alt="kate spade"/>
                <div className="photo-credit">Photo: KateSpade.com</div>
            </div>
            <div className="col-5">
                <h1>RUNNER UP: KATE SPADE MASKS</h1>
                <div className="article-cotent"><p>The pandemic has taught us all to spend money wisely!  With an influx of masks on the market, I recommend Kate Spade’s fleur nouveau non-medical mask.   You can shop for it here. It is fun and flirty even at this serious time.  The masks go for $9 a pop.  Another reason I like these masks is that 100% of net profits help fund 24/7crisis counseling.</p>
                 <p>Join the Kate Spade list and get 10% off.</p> 


                </div>
            </div>
        </div>
    </div>
    </>)
}
export default Home