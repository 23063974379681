import React from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "css/main.css"
import Navbar from "components/shared/Navbar"
import SiteRouter from "utils/SiteRouter"
import { mainAction } from 'redux/actions/index.actions'
import Footer from "components/shared/Footer"
const App = (props) =>{
  console.log(props.sidenav)
  return (
    <div className="App">
     <Navbar />
     <SiteRouter />
     <Footer />
    </div>
  );
}
function mapStateToProps(state) {
  return {
    sidenav:state.sidenavReducer.show
  }
}

function mapDispatchToProps(dispatch) {
  return {
      actions: bindActionCreators(
      {
          mainAction
      },
      dispatch
      )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
