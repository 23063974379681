import { actions } from "redux/actions/types"
//import { mainAction } from "redux/actions/index.actions"
let initialState = {
  show:false,
  navItems:[]
}
export default function sidenavReducer (state = initialState, action) {
  console.log(action)
    switch (action.type) {
      case actions.SHOW_SIDE_BAR: {
        state.show = true;
        return state
      }
      case actions.HIDE_SIDE_BAR: {
        state.show = false;
        return state
      }
      case actions.LOAD_SIDE_NAV_ITEMS:{
        return state
      }
      case actions.LOAD_SIDE_NAV_ITEMS_SUCCESS:{
        return state
      }
      case actions.LOAD_SIDE_NAV_ITEMS_FAIL:{
        return state
      }
      default: 
        return state
    }
}