import React from 'react'
const Footer = () => {
    return(<>
    <footer class="footer">
      <div class="container">
        <span class="text-muted">All rights reserved, Fashionledge.com.</span>
      </div>
    </footer>
    </>)
}
export default Footer